import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Box } from '@mui/material';
import Layout from '../shared/Layout/Layout';
import {
  Events,
  Description,
  Employment,
  StudentsWork,
  Graduates,
  News,
  FAQ,
  Programs,
  Questions,
  Intro,
} from '../widgets';
import {
  useLogosQuery,
  useStudentsQuery,
  useStudentsWorkQuery,
} from '../app/queries';
import { sendYandexMetrikaEvent } from '../helpers/yandexMetrika';
import { getAllEvents, getStats } from '../api/strapi';
import data, { mainLeadData } from '../pages-fsd/MainPage/model/main.data';
import HeadMaker from '../HeadMaker';
import { UX_EVENTS } from '../app/constants/events';
import useScrollToAnchor from '../helpers/useScrollToAnchor';

export function Head(props) {
  return <HeadMaker {...props} seo={data.seo} />;
}

function MainPage() {
  useScrollToAnchor();
  const { logosData } = useLogosQuery();
  const { studentsData } = useStudentsQuery();
  const { studentsWorkData } = useStudentsWorkQuery();
  const [events, setEvents] = useState([]);
  const [info, setInfo] = useState(data.employment.data.statistic);
  const [introInfo, setIntroInfo] = useState(data.intro.statistic);
  const graduatesStat = data.graduates.data.statistic.map((item) => {
    const updatedItem = info.find((el) => el.id === item.id);
    return {
      ...item,
      value: updatedItem.value,
      description: updatedItem.description,
    };
  });

  const [programsSeen, setProgramsSeen] = useState(false);
  const [freeEventsSeen, setFreeEventsSeen] = useState(false);

  const [programsBlockRef, programsInView] = useInView({
    threshold: 1,
    delay: 0,
  });
  const [freeEventsBlockRef, freeEventsInView] = useInView({
    threshold: 1,
    delay: 0,
  });

  useEffect(() => {
    if (!freeEventsSeen && freeEventsInView) {
      setFreeEventsSeen(true);
      sendYandexMetrikaEvent('events_view');
    }
    if (!programsSeen && programsInView) {
      setProgramsSeen(true);
      sendYandexMetrikaEvent('programms_view');
    }
  }, [freeEventsInView, programsInView]);

  useEffect(() => {
    const ac = new AbortController();
    sendYandexMetrikaEvent('main');
    const getData = async () => {
      try {
        const events = await getAllEvents(ac);
        const firstDOD_JS = events.find(
          (event) => event.titleEnum === 'DOD_JS',
        );
        const firstDOD_DS = events.find(
          (event) => event.titleEnum === 'DOD_DS',
        );
        const firstUXeventOrFirstUnusedEvent =
          events.find((event) => UX_EVENTS.includes(event.titleEnum)) ||
          events.find(
            (event) =>
              event.id !== firstDOD_JS.id && event.id !== firstDOD_DS.id,
          );

        const sortedEvents = [
          firstDOD_JS || { isLoading: true },
          firstDOD_DS || { isLoading: true },
          firstUXeventOrFirstUnusedEvent || { isLoading: true },
        ];
        const statData = await getStats();
        setEvents(sortedEvents);
        setInfo((prev) =>
          prev.map((item) => {
            const fetchedItem = statData.find((el) => el.id === item.id);
            return {
              ...item,
              value: fetchedItem?.statTitle,
              description: fetchedItem?.statSubTitle,
            };
          }),
        );
        setIntroInfo((prev) =>
          prev.map((item) => {
            const fetchedItem = statData.find((el) => el.id === item.id);
            return {
              ...item,
              value: fetchedItem?.statTitle,
              description: fetchedItem?.statSubTitle,
            };
          }),
        );
      } catch (e) {
        console.error(e);
      }
    };
    getData();
    return () => {
      ac.abort();
    };
  }, []);

  return (
    <Layout leadPage="JS Главная">
      <Box sx={{ overflow: 'hidden' }}>
        <Intro
          statistic={introInfo}
          ymSubmitEventType={data.ymEvents.mainProgramsTopClick}
        />
        <Programs
          {...data.programs}
          extraLeadData={mainLeadData.materials}
          blockRef={programsBlockRef}
        />
        <Description {...data.description} />
        <Employment
          {...data.employment}
          data={{ statistic: info, research: data.employment.data.research }}
        />
        <StudentsWork {...data.work} logosData={logosData} />
        <Events
          {...data.events}
          events={events}
          blockRef={freeEventsBlockRef}
        />
        <Graduates
          {...data.graduates}
          data={{
            statistic: graduatesStat,
            people: data.graduates.data.people,
          }}
          studentsData={studentsData}
          studentsWorkData={studentsWorkData}
          noBg
        />
        <News {...data.news} />
        <FAQ {...data.faq} ymSubmitEventType={data.ymEvents.mainFaqClick} />
        <Questions {...data.questions} extraLeadData={mainLeadData.question} />
      </Box>
    </Layout>
  );
}

export default MainPage;
